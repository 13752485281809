import '@shoelace-style/shoelace/dist/themes/dark.css';
import '@shoelace-style/shoelace/dist/themes/light.css';

import { setDialogAnimations, setDialogBehavior } from '@/components/handlers/dialog-animation';
import elements from '@/plugins/@shoelace/elements';
import { type Icons, iconLibrary } from '@/plugins/@shoelace/icons';
import { type SlIcon, registerIconLibrary } from '@shoelace-style/shoelace';

const components = (): Promise<CustomElementConstructor>[] => elements;

(async (): Promise<void> => {
	await Promise.allSettled(components());

	iconLibrary.map((library: Icons) =>
		registerIconLibrary(library.name, {
			resolver: (name: SlIcon | string): string => `${library.path}/${name}.svg`,
			mutator:
				library.name === 'heroicons'
					? (svg: SVGElement): void => {
							svg.setAttribute('fill', 'none');
							svg.setAttribute('stroke', 'currentColor');
						}
					: undefined,
		}),
	);

	setDialogAnimations();

	window.onresize = setDialogAnimations;

	setDialogBehavior();

	document.body.classList.add('ready');
})();

// HMR (Hot Module Replacement)
if (module.hot) {
	module.hot.accept();
	module.hot.dispose((): void => {
		console.clear();
	});
}
