import { ref } from '@/components/utils/dom';
import type { IconNames, ThemesTypes } from '@/types/default';
import type { SlIconButton } from '@shoelace-style/shoelace';

export default class ThemeProvider {
	private readonly selectors: {
		action?: SlIconButton | null;
		aside?: SlIconButton | null;
	};

	constructor() {
		this.selectors = this.buttons();

		if (!localStorage.getItem('color-theme')) {
			localStorage.setItem('color-theme', 'light');
		}
	}

	private readonly buttons = (): {
		action?: SlIconButton | null;
		aside?: SlIconButton | null;
	} => ({
		action: <SlIconButton>ref("[data-navbar='dark-mode-switcher']"),
		aside: <SlIconButton>ref("[data-navbar='dark-mode-switcher-aside']"),
	});

	private readonly setTheme = (theme: string): void => {
		const oppositeTheme: ThemesTypes = theme === 'light' ? 'dark' : 'light';
		const iconName: IconNames = theme === 'light' ? 'moon' : 'sun';

		document.documentElement.classList.remove(oppositeTheme);
		document.documentElement.classList.add(theme);

		if (this.selectors.action) {
			this.selectors.action.setAttribute('name', iconName);
		}

		if (this.selectors.aside) {
			this.selectors.aside.setAttribute('name', iconName);
		}

		document.documentElement.setAttribute('style', `color-scheme: ${theme};`);

		localStorage.setItem('color-theme', theme);
	};

	private readonly click = (): void => {
		const currentTheme: string | null = localStorage.getItem('color-theme');
		const newTheme: ThemesTypes = currentTheme === 'light' ? 'dark' : 'light';

		this.setTheme(newTheme);
	};

	public start = (): void => {
		const currentTheme: string | null = localStorage.getItem('color-theme');
		const isDarkMode: boolean =
			currentTheme === 'dark' ||
			(!('color-theme' in localStorage) &&
				window.matchMedia('(prefers-color-scheme: dark)').matches);

		this.setTheme(isDarkMode ? 'dark' : 'light');

		if (this.selectors.action) {
			this.selectors.action.addEventListener('click', this.click);
		}

		if (this.selectors.aside) {
			this.selectors.aside.addEventListener('click', this.click);
		}
	};
}
