import type { Dropdown, Interceptor } from '@/types/default';
import type { AlpineComponent } from 'alpinejs';

export default {
	navigationMenuOpen: false,
	navigationMenu: '',
	navigationMenuCloseDelay: 200,
	navigationMenuCloseTimeout: 300,
	navigationMenuLeave(): void {
		this.navigationMenuCloseTimeout = setTimeout((): void => {
			this.navigationMenuClose();
		}, this.navigationMenuCloseDelay);
	},
	navigationMenuReposition(navigationElement: HTMLElement): void {
		this.navigationMenuClearCloseTimeout();

		const navigationDropdown: Interceptor = this.$refs?.navigationDropdown;
		const { offsetLeft, offsetWidth } = navigationElement;

		navigationDropdown!.style.left = `${offsetLeft}px`;
		navigationDropdown!.style.marginLeft = `${offsetWidth / 2}px`;
	},
	navigationMenuClearCloseTimeout(): void {
		clearTimeout(this.navigationMenuCloseTimeout);
	},
	navigationMenuClose(): void {
		this.navigationMenuOpen = false;
		this.navigationMenu = '';
	},
} as AlpineComponent<Dropdown>;
