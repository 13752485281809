import { Breakpoints } from '@/components/enums/breakpoints.enum';
import { listener, refs } from '@/components/utils/dom';
import { logger } from '@/components/utils/logger';
import type { ElementAnimation } from '@/types/default';
import type { MediQInput } from '@medi-q/core';
import { type MediQ, createMediQ } from '@medi-q/core';
import type { SlDialog } from '@shoelace-style/shoelace';
import { setDefaultAnimation } from '@shoelace-style/shoelace/dist/utilities/animation-registry';

const bounceIn: ElementAnimation = {
	keyframes: [
		{ transform: 'scale(0)', opacity: '0' },
		{ transform: 'scale(1.05)', opacity: '1' },
		{ transform: 'scale(0.95)', opacity: '1' },
		{ transform: 'scale(1)', opacity: '1' },
	],
	options: {
		duration: 600,
		easing: 'ease-in-out',
	},
};

const bounceOut: ElementAnimation = {
	keyframes: [
		{ transform: 'scale(1)', opacity: '1' },
		{ transform: 'scale(0.95)', opacity: '0.75' },
		{ transform: 'scale(0)', opacity: '0' },
	],
	options: {
		duration: 600,
		easing: 'ease-in-out',
	},
};

const slideUp: ElementAnimation = {
	keyframes: [
		{ transform: 'translateY(100%)', opacity: '0' },
		{ transform: 'translateY(-10%)', opacity: '1' },
		{ transform: 'translateY(0)', opacity: '1' },
	],
	options: {
		duration: 600,
		easing: 'ease-in-out',
	},
};

const slideDown: ElementAnimation = {
	keyframes: [
		{ transform: 'translateY(0)', opacity: '1' },
		{ transform: 'translateY(-10%)', opacity: '1' },
		{ transform: 'translateY(100%)', opacity: '0' },
	],
	options: {
		duration: 600,
		easing: 'ease-in-out',
	},
};

// set animations for dialogs
const mediQ: MediQ = createMediQ({
	tiny: Breakpoints.tiny,
	small: Breakpoints.small,
	medium: Breakpoints.medium,
	large: Breakpoints.large,
});
const breakpoint: string = <MediQInput>mediQ('min-tiny');
const query: MediaQueryList = matchMedia(breakpoint);

export const setDialogAnimations = (): void => {
	setDefaultAnimation('dialog.show', query.matches ? bounceIn : slideUp);
	setDefaultAnimation('dialog.hide', query.matches ? bounceOut : slideDown);
};

// prevent all dialogs from closing when clicking on the overlay
export const setDialogBehavior = (): void => {
	const dialogs: NodeListOf<SlDialog> | null = refs('sl-dialog');

	dialogs.forEach(async (dialog: SlDialog): Promise<void> => {
		try {
			await dialog.updateComplete;

			listener(dialog, 'sl-request-close', (event: CustomEvent): void => {
				const { source } = event.detail;

				if (source === 'overlay') {
					event.preventDefault();
				}
			});
		} catch (error: unknown) {
			logger.fatal(error);
		}
	});
};
