export type Icons = {
	name: string;
	path: string;
	mutator?: (svg: SVGElement) => void;
};

export const iconLibrary: Icons[] = [
	{
		name: 'heroicons',
		path: '/assets/icons/heroicons/outline',
	},
	{
		name: 'heroicons-solid',
		path: '/assets/icons/heroicons/solid',
	},
	{
		name: 'wefix',
		path: '/assets/icons/wefix',
	},
	{
		name: 'branding',
		path: '/assets/icons/wefix/branding',
	},
	{
		name: 'colors',
		path: '/assets/icons/wefix/colors',
	},
];
