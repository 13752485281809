import dayjs from '@/plugins/@dayjs';
import type { DatePicker } from '@/types/default';
import type { AlpineComponent } from 'alpinejs';

export default {
	datePickerOpen: false,
	datePickerValue: '',
	datePickerFormat: 'DD/MM/YYYY',
	datePickerMonth: 0,
	datePickerYear: 0,
	datePickerDay: 0,
	datePickerDaysInMonth: [],
	datePickerBlankDaysInMonth: [],
	datePickerYears: Array.from(
		{ length: 5 },
		(_, i) => new Date().getFullYear() - i,
	),
	datePickerMonths: Array.from({ length: 12 }, (_, i) => i),
	datePickerMonthNames: Array.from({ length: 12 }, (_, i) =>
		dayjs().month(i).format('MMMM'),
	),
	datePickerShortMonthNames: Array.from({ length: 12 }, (_, i) =>
		dayjs().month(i).format('MMM'),
	),
	datePickerDays: Array.from({ length: 7 }, (_, i) =>
		dayjs()
			.day((i + 1) % 7)
			.format('ddd'),
	),
	datePickerDayClicked(day: number): void {
		const selectedDate = new Date(
			this.datePickerYear,
			this.datePickerMonth,
			day,
		);
		this.datePickerDay = day;
		this.datePickerValue = this.datePickerFormatDate(selectedDate);
		this.datePickerIsSelectedDate(day);
		this.datePickerOpen = false;
	},
	datePickerPreviousMonth(): void {
		if (this.datePickerMonth === 0) {
			this.datePickerYear -= 1;
			this.datePickerMonth = 12;
		}

		this.datePickerMonth -= 1;

		this.datePickerCalculateDays();
	},
	datePickerNextMonth(): void {
		if (this.datePickerMonth === 11) {
			this.datePickerMonth = 0;
			this.datePickerYear += 1;
		} else {
			this.datePickerMonth += 1;
		}

		this.datePickerCalculateDays();
	},
	datePickerIsSelectedDate(day: number): boolean {
		const d = new Date(this.datePickerYear, this.datePickerMonth, day);

		return this.datePickerValue === this.datePickerFormatDate(d);
	},
	datePickerIsToday(day: number): boolean {
		const today = new Date();
		const d = new Date(this.datePickerYear, this.datePickerMonth, day);

		return today.toDateString() === d.toDateString();
	},
	datePickerCalculateDays(): void {
		const daysInMonth: number = new Date(
			this.datePickerYear,
			this.datePickerMonth + 1,
			0,
		).getDate();
		const dayOfWeek: number = new Date(
			this.datePickerYear,
			this.datePickerMonth,
		).getDay();
		const blankdaysArray: number[] = Array.from(
			{ length: dayOfWeek },
			(_, i) => i + 1,
		);
		const daysArray: number[] = Array.from(
			{ length: daysInMonth },
			(_, i) => i + 1,
		);

		this.datePickerBlankDaysInMonth = blankdaysArray;
		this.datePickerDaysInMonth = daysArray;
	},
	datePickerFormatDate(date: Date): string {
		const day: string = this.datePickerDays[date.getDay()];
		const month: string = this.datePickerMonthNames[date.getMonth()];
		const monthShort: string = this.datePickerMonthNames[
			date.getMonth()
		].substring(0, 3);
		const monthNumber: string = `0${
			Number.parseInt(String(date.getMonth()), 10) + 1
		}`.slice(-2);
		const year: number = date.getFullYear();
		const formattedDate: string = `0${date.getDate()}`.slice(-2);

		if (this.datePickerFormat === 'M d, Y') {
			return `${monthShort} ${formattedDate}, ${year}`;
		}

		if (this.datePickerFormat === 'MM-DD-YYYY') {
			return `${monthNumber}-${formattedDate}-${year}`;
		}

		if (this.datePickerFormat === 'DD-MM-YYYY') {
			return `${formattedDate}-${monthNumber}-${year}`;
		}

		if (this.datePickerFormat === 'DD/MM/YYYY') {
			return `${formattedDate}/${monthNumber}/${year}`;
		}

		if (this.datePickerFormat === 'YYYY-MM-DD') {
			return `${year}-${monthNumber}-${formattedDate}`;
		}

		if (this.datePickerFormat === 'D d M, Y') {
			return `${day} ${formattedDate} ${monthShort} ${year}`;
		}

		return `${month} ${formattedDate}, ${year}`;
	},
	datePickerGoToToday(): void {
		const currentDate: Date = new Date();

		this.datePickerMonth = currentDate.getMonth();
		this.datePickerYear = currentDate.getFullYear();
		this.datePickerDay = currentDate.getDate();

		this.datePickerCalculateDays();
	},
	datePickerResetMonth(): void {
		const currentDate: Date = new Date();
		this.datePickerMonth = currentDate.getMonth();
		this.datePickerYear = currentDate.getFullYear();

		this.datePickerCalculateDays();
	},
	datePickerClearInput(): void {
		this.datePickerValue = '';
		this.datePickerOpen = false;

		this.datePickerResetMonth();
	},
	datePickerSelectYear(year: number): void {
		this.datePickerYear = year;

		this.datePickerCalculateDays();
	},
	datePickerSelectMonth(month: number): void {
		this.datePickerMonth = month;

		this.datePickerCalculateDays();
	},
	AlpineDatePickerInit(): void {
		const currentDate: Date = new Date();

		this.datePickerMonth = currentDate.getMonth();
		this.datePickerYear = currentDate.getFullYear();
		this.datePickerDay = currentDate.getDay();

		this.datePickerCalculateDays();
	},
} as AlpineComponent<DatePicker>;
