export default [
	customElements.whenDefined('sl-alert'),
	customElements.whenDefined('sl-animated-image'),
	customElements.whenDefined('sl-animation'),
	customElements.whenDefined('sl-avatar'),

	customElements.whenDefined('sl-badge'),
	customElements.whenDefined('sl-breadcrumb'),
	customElements.whenDefined('sl-breadcrumb-item'),
	customElements.whenDefined('sl-button'),
	customElements.whenDefined('sl-button-group'),

	customElements.whenDefined('sl-card'),
	customElements.whenDefined('sl-carousel'),
	customElements.whenDefined('sl-carousel-item'),
	customElements.whenDefined('sl-checkbox'),
	customElements.whenDefined('sl-color-picker'),
	customElements.whenDefined('sl-copy-button'),

	customElements.whenDefined('sl-details'),
	customElements.whenDefined('sl-dialog'),
	customElements.whenDefined('sl-divider'),
	customElements.whenDefined('sl-drawer'),
	customElements.whenDefined('sl-dropdown'),

	customElements.whenDefined('sl-format-bytes'),
	customElements.whenDefined('sl-format-date'),
	customElements.whenDefined('sl-format-number'),

	customElements.whenDefined('sl-icon'),
	customElements.whenDefined('sl-icon-button'),
	customElements.whenDefined('sl-image-comparer'),
	customElements.whenDefined('sl-include'),
	customElements.whenDefined('sl-input'),

	customElements.whenDefined('sl-menu'),
	customElements.whenDefined('sl-menu-item'),
	customElements.whenDefined('sl-menu-label'),
	customElements.whenDefined('sl-mutation-observer'),

	customElements.whenDefined('sl-option'),

	customElements.whenDefined('sl-popup'),
	customElements.whenDefined('sl-progress-bar'),
	customElements.whenDefined('sl-progress-ring'),

	customElements.whenDefined('sl-qr-code'),

	customElements.whenDefined('sl-radio'),
	customElements.whenDefined('sl-radio-button'),
	customElements.whenDefined('sl-radio-group'),
	customElements.whenDefined('sl-range'),
	customElements.whenDefined('sl-rating'),
	customElements.whenDefined('sl-relative-time'),
	customElements.whenDefined('sl-resize-observer'),

	customElements.whenDefined('sl-select'),
	customElements.whenDefined('sl-skeleton'),
	customElements.whenDefined('sl-spinner'),
	customElements.whenDefined('sl-split-panel'),
	customElements.whenDefined('sl-switch'),

	customElements.whenDefined('sl-tab'),
	customElements.whenDefined('sl-tab-group'),
	customElements.whenDefined('sl-tab-panel'),
	customElements.whenDefined('sl-tag'),
	customElements.whenDefined('sl-textarea'),
	customElements.whenDefined('sl-tooltip'),
	customElements.whenDefined('sl-tree'),
	customElements.whenDefined('sl-tree-item'),

	customElements.whenDefined('sl-visually-hidden'),
];
