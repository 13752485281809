import '@/plugins/@shoelace';
import '@/styles/app.scss';

import { defineElement } from '@lordicon/element';
import lottie from 'lottie-web';

import AlpineDatePicker from '@/plugins/@date-picker';
import AlpineDropdown from '@/plugins/@dropdown';
import Alpine from 'alpinejs';

import ThemeProvider from '@/components/class/theme-provider';

import { logger } from '@/components/utils/logger';

import drawerMenu from '@/components/modules/drawer-menu';
import type { AlpinePlugins, Module } from '@/types/default';

// Lordicon (animated icons)
defineElement(lottie.loadAnimation);

// Alpine.js
window.Alpine = Alpine;

const registerAlpinePlugins = (): void => {
	const plugins: AlpinePlugins = {
		AlpineDatePicker,
		AlpineDropdown,
	};

	for (const [name, plugin] of Object.entries(plugins)) {
		Alpine.data(name, () => plugin);
	}
};

document.addEventListener('alpine:init', registerAlpinePlugins);

Alpine.start();

// Dark mode theme
new ThemeProvider().start();

(async (): Promise<void> => {
	try {
		// animate total number of WeFix corners in the footer, with Motion.one
		const animatedFooter: Module = await import(
			'@/components/modules/animated-footer'
		);
		animatedFooter.default();

		// drawer menu in mobile
		const drawerMobileMenu: Module = await import(
			'@/components/modules/drawer-menu'
		);
		drawerMobileMenu.default();

		// open and hide the log-out dialog
		const logOutDialog: Module = await import(
			'@/components/modules/logout-dialog'
		);
		logOutDialog.default();

		// run Lottie Files animations figures
		const lottieAnimations: Module = await import(
			'@/components/modules/lottie-figures'
		);
		lottieAnimations.default();

		// events for the tab group of the aside navigation bar (show and hide the footer buttons)
		const tabGroupNavbar: Module = await import(
			'@/components/modules/tab-group-navbar'
		);
		tabGroupNavbar.default();

		// search engine (Orama)
		const oramaSearch: Module = await import('@/components/plugins/@orama');
		oramaSearch.default();
	} catch (error: unknown) {
		logger.fatal(error);
	}
})();

// HMR (Hot Module Replacement)
if (module.hot) {
	module.hot.accept();
	module.hot.dispose((): void => {
		console.clear();
	});
}
